import React from 'react'

export default ({items}) => {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <a
            href={item.href}
            target="_blank"
            rel="noopener noreferrer">
            {item.title}
          </a>
        </li>
      ))}
    </ul>
  )
}
