import React from 'react'
import Layout from '../components/layout'
import Links from '../components/links';

export default ({ pageContext: { release } }) => {

  return (
    <Layout 
      title={`${release.project.name} - ${release.title}`}
      image={release.image}>
      <h1>
        {release.project.name} - {release.title}
      </h1>
      <p>
        {release.date.substr(0, 4)} - {release.label}
      </p>
      {release.player && (
        <iframe
          title="Player"
          src={`https://bandcamp.com/EmbeddedPlayer/album=${release.player}/size=large/bgcol=ffffff/linkcol=333333/tracklist=false/artwork=small/transparent=true/`} seamless>
        </iframe>
      )}
      {release.links && (
        <Links
          items={release.links}
        />
      )}
    </Layout>
  )
}
